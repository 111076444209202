.wrapper {
  background-color: #e5ddd5;
  min-height: 100vh;
  color: black;
  font-size: 12px;
}
.loginImg {
  margin-top: 14vh;
  height: 60vh;
}
.loginForm {
  margin-top: 200px;
  width: 20rem;
  color: #075e54;
}
#login-is-loader {
  width: 100px;
  height: auto;
}
#login-sign-in-btn {
  background-color: #cfe9ba;
  color: #075e54;
}